import { t } from 'shared/i18n';
import AnnouncementBarStyle from '@sqs/enums/AnnouncementBarStyle';
// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/announcement-bar.css';

var ANNOUNCEMENT_BAR_EVENTS = {
  READY: 'announcementBar:event:ready',
  UPDATE: 'announcementBar:event:update',
  RESET_VISIBILITY: 'announcementBar:event:resetVisibility',
  CLOSE: 'announcementBar:event:close' };


/**
 * @module squarespace-announcement-bar
 */
YUI.add('squarespace-announcement-bar', function (Y) {

  /**
   * Displays an announcement bar when
   * Configuration > Overlays > Announcement Bar
   * is enabled.
   *
   * @class AnnouncementBar
   * @namespace Squarespace.Widgets
   * @extends SSWidget
   * @contructor
   */
  var LS_CLOSED = 'closed';
  var LS_KEY = 'squarespace-announcement-bar';

  Y.namespace('Squarespace.Widgets');

  var Bar =
  Y.Squarespace.Widgets.AnnouncementBar =
  Y.Base.create('AnnouncementBar', Y.Squarespace.Widgets.SSWidget, [], {

    initializer: function () {
      this._bb = this.get('boundingBox');
      this._cb = this.get('contentBox');

      this._publishEvents();
      this._addActionListeners();
    },
    destructor: function () {
      Y.Array.invoke(this.eventListeners, 'detach');
      this.eventListeners = [];
    },


    render: function (forceRendering) {
      var requiresRendering = forceRendering || this._checkIfNewMessage();

      if (requiresRendering) {

        var dropzone = this._getDropzone();
        Bar.superclass.render.call(this, dropzone);

      } else {
        this._bindTweakEvents();
      }
    },


    bindUI: function () {
      Bar.superclass.bindUI.call(this);

      this.get('boundingBox').delegate('click', this._closeBar, '.' + this.getClassName('close'), this);
      this.get('boundingBox').delegate('keydown', function (e) {
        // Handle Enter (13) and Space (32) keydown events
        // (the correct behavior for buttons).
        if (e.keyCode === 13 || e.keyCode === 32) {
          this._closeBar(e);
        }
      }, '.' + this.getClassName('close'), this);

      this._bindTweakEvents();

      this.after([
      'textChange',
      'clickthroughUrlChange'],
      function () {
        this.get('contentBox').empty();
        this.get('contentBox').prepend(this.renderTemplate());
      });

      this.on('visibleChange', this._checkIfNewMessage, this);
    },


    syncUI: function () {
      Bar.superclass.syncUI.call(this);

      var style = parseInt(this.get('style'), 10);
      var text = this.get('text');

      if (Y.Lang.isObject(text)) {
        text = text.html;
      }

      if (style !== AnnouncementBarStyle.NONE && text) {
        this.show();
      } else {
        this.hide();
      }

    },
    /**
     * @method getHeight
     */
    getHeight: function () {
      return this._bb.get('clientHeight');
    },


    _bindTweakEvents: function () {
      Y.Global.on('tweak:beforeopen', this._onTweakOpen, this);
    },


    _onTweakOpen: function () {
      var isRendered = this.get('rendered');

      if (!isRendered) {
        this.render(true);
      }

      this.show();
    },


    _closeBar: function (e) {
      // Avoid <a>
      if (Y.namespace('Squarespace.Widgets.AnnouncementBar').isClosed()) {
        return;
      }
      if (e) {
        e.preventDefault();
      }

      this.hide();

      // Prevent losing focus (beneficial for screen reader and keyboard users).
      // The attribute that's used for the selector may be set in template framework JSON-T.
      var firstFocusableElement = Y.one('[data-first-focusable-element]');
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }

      this._recordClose();

      // 7.1 template reserves min-height to reduce CLS caused by announcement bar.
      // Remove the min-height attribute on close.
      document.body.classList.remove('announcement-bar-reserved-space');
    },


    /**
     * The bar is appended to <body> by default,
     * unless there's another element that already
     * has the dropzone class
     *
     * @method _getDropzone
     * @private
     */
    _getDropzone: function () {
      var dropzone = Y.one(this.get('dropzone'));

      if (!Y.Lang.isNull(dropzone)) {

        // Insert before existing elements
        var placeholder = Y.Node.create('<div class="sqs-announcement-bar-custom-location"></div>');

        dropzone.insert(placeholder, 0);
        dropzone = placeholder;

        this.set('isCustomPositioned', true);
      }

      return dropzone;
    },


    /**
     * Checks if the message to be displayed
     * was already rendered to the current visitor.
     *
     * Relies on localStorage.
     *
     * @method  _checkIfNewMessage
     * @private
     * @return  {Boolean}
     */
    _checkIfNewMessage: function () {
      var isDifferent = true;
      var saveLatest = false;

      if (localStorage) {

        var latest = {
          text: this.get('text'),
          clickthroughUrl: this.get('clickthroughUrl') };


        var stored = this._getLocalStorageData();

        if (stored) {
          var closed = stored[LS_CLOSED];
          delete stored[LS_CLOSED];

          if (latest.clickthroughUrl === null) {
            delete latest.clickthroughUrl;
          }

          var stringLatest = Y.JSON.stringify(latest);
          var stringStored = Y.JSON.stringify(stored);

          if (stringLatest === stringStored && closed === true) {
            isDifferent = false;
          } else {
            saveLatest = true;
          }

        } else {
          saveLatest = true;
        }

        if (saveLatest === true) {
          latest[LS_CLOSED] = false;
          this._saveToLocalStorage(latest);
        }
      }

      return isDifferent;
    },


    /**
     * @method  _getLocalStorageData
     * @private
     * @return or null
     */
    _getLocalStorageData: function () {
      var key = LS_KEY;
      var output = null;

      if (localStorage) {
        try {
          var raw = localStorage.getItem(key);
          output = Y.JSON.parse(raw);
        } catch (e) {
          output = null;
        }
      }

      return output;
    },


    /**
     * Save to local storage
     * @method  _saveToLocalStorage
     * @param   {} value
     * @private
     */
    _saveToLocalStorage: function (value) {
      var key = LS_KEY;

      if (localStorage) {

        if (typeof value !== 'string') {
          value = Y.JSON.stringify(value);
        }

        try {
          localStorage.setItem(key, value);
        } catch (e) {
          if (__DEV__) {
            console.warn(e);
          }
        }
      }
    },


    /**
     * @method  _recordClose
     * @private
     */
    _recordClose: function () {
      var stored = this._getLocalStorageData();

      if (stored) {
        stored[LS_CLOSED] = true;
        this._saveToLocalStorage(stored);
      }
    },
    _publishEvents: function () {
      /**
       * When receiving this event the bar will show
       * @publish ANNOUNCEMENT_BAR_EVENTS.RESET_VISIBILITY
       */
      this.publish(ANNOUNCEMENT_BAR_EVENTS.RESET_VISIBILITY);
      /**
       * Event used to update the content (style, text and clickthrough) of the bar
       * @publish ANNOUNCEMENT_BAR_EVENTS.UPDATE
       */
      this.publish(ANNOUNCEMENT_BAR_EVENTS.UPDATE);
      /**
       * Event used to close the bar
       * @publish ANNOUNCEMENT_BAR_EVENTS.CLOSE
       */
      this.publish(ANNOUNCEMENT_BAR_EVENTS.CLOSE);
      /**
       * Fired when the announcement bar is ready. Will send the instance of the bar
       * @publish ANNOUNCEMENT_BAR_EVENTS.READY
       */
      Y.Global.publish(ANNOUNCEMENT_BAR_EVENTS.READY);
    },
    /**
     * Setup listeners on Alt actions to update the announcement bar from the React config panel
     * @method  _addActionListeners
     * @private
     */
    _addActionListeners: function () {

      // Listening to events thrown by announcement-bar-bridge, and storing them to be able to detach them later on
      this.eventListeners = [
      this.on(ANNOUNCEMENT_BAR_EVENTS.RESET_VISIBILITY, function () {
        Y.Squarespace.Widgets.AnnouncementBar.resetVisibility();
        this.render(true);
        this.syncUI();
      }.bind(this)),

      // Listen to the action used to update the content (style, text and clickthrough url)
      this.on(ANNOUNCEMENT_BAR_EVENTS.UPDATE, function (payload) {
        this.setAttrs({
          text: payload.text.html.toString(),
          style: payload.style,
          clickthroughUrl: payload.clickthroughUrl });


        if (!this.get('rendered')) {
          this.render();
        }
        this.syncUI();
      }.bind(this)),

      // Listen to the action used to close the action bar from React
      this.on(ANNOUNCEMENT_BAR_EVENTS.CLOSE, function () {
        this._closeBar();
      }.bind(this))];


      Y.Global.fire(ANNOUNCEMENT_BAR_EVENTS.READY, {
        bar: this });

    } },


  {

    CSS_PREFIX: 'sqs-announcement-bar',
    HANDLEBARS_TEMPLATE: 'announcement-bar.html',
    EVENTS: ANNOUNCEMENT_BAR_EVENTS,

    ATTRS: {
      /**
       * @attribute text
       */
      text: {
        value: null },


      closeButtonLabel: {
        value: t("Close Announcement") },





      /**
       * @attribute isCustomPositioned
       * @type      {Boolean}
       */
      isCustomPositioned: {
        value: false },


      /**
       * @attribute clickthroughUrl
       * @type
       */
      clickthroughUrl: {
        value: null },


      /**
       * Selector
       * @attribute dropzone
       * @type      {String}
       * @default .sqs-announcement-bar-dropzone
       */
      dropzone: {
        value: '.sqs-announcement-bar-dropzone' } } });





  /**
   * Reset Visibility
   */
  Y.namespace('Squarespace.Widgets.AnnouncementBar').resetVisibility = function () {
    if (localStorage) {
      try {
        localStorage.removeItem(LS_KEY);
      } catch (e) {
        if (__DEV__) {
          console.warn(e);
        }
      }
    }
  };


  /**
   * Check if Bar is closed
   */
  Y.namespace('Squarespace.Widgets.AnnouncementBar').isClosed = function () {
    var closed = false;

    if (localStorage) {
      try {
        var raw = localStorage.getItem(LS_KEY);
        var output = Y.JSON.parse(raw);

        if (!Y.Lang.isNull(output)) {
          closed = output[LS_CLOSED];
        }

      } catch (e) {
        if (__DEV__) {
          console.warn(e);
        }
      }
    }

    return closed;
  };



  // Initializer
  Y.config.win.Squarespace.onInitialize(Y, function () {

    // If you've gotten here, you're either in an
    // unauthenticated session and the script has
    // been trigger loaded, in which case common.js
    // has already checked for the presence of
    // announcementBarSettings, OR you are in an
    // authenticated session, where we need to
    // initialize AnnouncementBar even if the user
    // has set no AnnouncementBar, so if they turn
    // it on then it's there.


    // For the second case we listed above, set a
    // fallback for announcementBarSettings to blank,
    // so the bar will initialize but not render.
    var announcementBarSettings =
    Y.config.win.Static.SQUARESPACE_CONTEXT.websiteSettings.announcementBarSettings ||
    { style: 1, text: '' };

    Y.namespace('Squarespace.Singletons');

    if (!Y.Squarespace.Singletons.AnnouncementBar) {

      var text = announcementBarSettings.text;

      /*
       * `label` is an Object when the user is
       * logged in, String when logged out
       */
      if (Y.Lang.isObject(text)) {
        text = text.html;
      }

      Y.Squarespace.Singletons.AnnouncementBar = new Bar({

        text: text,
        clickthroughUrl: announcementBarSettings.clickthroughUrl });



      // If logged in, the announcement bar will init
      // (in case the user enables the bar) in accordance
      // to the conditional set in common, but the bar
      // will not render unless set to style 2.
      if (Y.config.win.Static.SQUARESPACE_CONTEXT.showAnnouncementBar) {
        Y.Squarespace.Singletons.AnnouncementBar.render();
      }

    }

  });


}, '1.0', {
  requires: [
  'base',
  'json',
  'node',
  'squarespace-announcement-bar-template',
  'squarespace-ss-widget'] });