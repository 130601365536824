/* eslint-disable */
// Generated from seed-templates/handlebars-module-template.js
YUI.add('squarespace-announcement-bar-template', function(Y) {
  var Handlebars = Y.Handlebars;

  (function() { var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {}; templates["announcement-bar.html"] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, stack2, self=this, functionType="function", escapeExpression=this.escapeExpression;

function program1(depth0,data) {
  
  var buffer = "", stack1, stack2;
  buffer += "\n<a\n  class=\"sqs-announcement-bar-url\"\n  ";
  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.clickthroughUrl),stack1 == null || stack1 === false ? stack1 : stack1.newWindow), {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n  href=\""
    + escapeExpression(((stack1 = ((stack1 = depth0.clickthroughUrl),stack1 == null || stack1 === false ? stack1 : stack1.url)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"\n  "
    + "\n  aria-labelledby=\"announcement-bar-text-inner-id\"\n></a>\n";
  return buffer;
  }
function program2(depth0,data) {
  
  
  return "target=\"_blank\"";
  }

  stack2 = helpers['if'].call(depth0, ((stack1 = depth0.clickthroughUrl),stack1 == null || stack1 === false ? stack1 : stack1.url), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n\n<div class=\"sqs-announcement-bar-text\">\n  <span\n    class=\"sqs-announcement-bar-close\"\n    tabindex=\"0\"\n    role=\"button\"\n    aria-label=\"";
  if (stack2 = helpers.closeButtonLabel) { stack2 = stack2.call(depth0, {hash:{},data:data}); }
  else { stack2 = depth0.closeButtonLabel; stack2 = typeof stack2 === functionType ? stack2.apply(depth0) : stack2; }
  buffer += escapeExpression(stack2)
    + "\"></span>\n  <div\n    "
    + "\n    id=\"announcement-bar-text-inner-id\"\n    class=\"sqs-announcement-bar-text-inner\"\n  >\n    ";
  if (stack2 = helpers.text) { stack2 = stack2.call(depth0, {hash:{},data:data}); }
  else { stack2 = depth0.text; stack2 = typeof stack2 === functionType ? stack2.apply(depth0) : stack2; }
  if(stack2 || stack2 === 0) { buffer += stack2; }
  buffer += "\n  </div>\n</div>\n";
  return buffer;
  });})()

  var filename = 'announcement-bar.html';
  Y.Handlebars.registerPartial(filename.replace('/', '.'), Handlebars.templates[filename]);

}, '1.0', {
  requires: [
    "handlebars-base"
  ]
});
